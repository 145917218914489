import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';
import Seo from '../components/seo';
import {
  PageWrapper,
  PageInner,
  PageTitle,
  GridAlSophro,
  ColGauche,
  ColDroite,
  Text
} from '../components/Elements';

export const creditsQuery = graphql`
query  creditQuery($locale: String!){
     page: datoCmsPageCredit(locale: {eq: $locale}) {
      titre
      contenu
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      illustration {
        gatsbyImageData(
          placeholder: BLURRED,
          forceBlurhash: false,           
        )
      }
    }
  }
`;

const CreditsPage = ({data}) => {
  
  const { titre, contenu, seoMetaTags, illustration } = data.page;

  return (
    <Fragment>
      <Seo meta={seoMetaTags} />
      <PageWrapper>
       
        <PageInner>
          <GridAlSophro>
            <ColGauche>
              <GatsbyImage image={illustration.gatsbyImageData} alt={titre} />
            </ColGauche>
  
            <ColDroite>
              <PageTitle>{titre}</PageTitle>
              <Text dangerouslySetInnerHTML={{ __html: contenu }} />
            </ColDroite>
          </GridAlSophro>
        </PageInner>
      </PageWrapper>
    </Fragment>
 );
}

export default  CreditsPage